import React from "react";
import { convertToLocalDate } from "../../../../Utils/Common/utils";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({ editContentSlider, GridFlag }) => {
  const dataColumns = [
    {
      field: "uc_ossi_run_ord",
      title: "Item Order",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editContentSlider(props.dataItem);
          }}
        >
          {"Step " + props.dataItem.uc_ossi_run_ord}
        </td>
      ),
    },
    {
      field: "uc_ossi_run_seq",
      title: "Run Sequence",
      editable: false,
      show: false,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "tenant_name",
      title: "Tenant",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: "uc_ossi_app_test_id",
      title: "Test Id",
      editable: false,
      show: true,
      width: "250px",
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_id",
      title: "Test Case Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_user_cnt",
      title: "No Of Concurrent User",
      editable: false,
      show: true,
      width: "300px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_step_run_till_expr",
      title: "Run While Condition Is True",
      editable: false,
      show: true,
      width: "300px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "uc_ossi_disable_flg",
      title: "Disabled",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      cell: GridFlag,
    },
    {
      field: "uc_ossi_descr",
      title: "Description",
      editable: false,
      show: true,
      width: "300px",
      minResizableWidth: 50,
    },
    {
      field: "ins_user_id",
      title: "Created By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "ins_dt",
      title: "Created Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.ins_dt)}</td>,
    },
    {
      field: "last_upd_user_id",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "last_upd_dt",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>{convertToLocalDate(props.dataItem.last_upd_dt)}</td>
      ),
    },
  ];

  return dataColumns;
};
export default DataColumns;
