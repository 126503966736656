import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { RUNSETARGUMENTS } from "../../../constants/applicationConstants";
import { DataTableHoc, Loader, ErrorFallback } from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import "./RunSetArguments.scss";
const BaseRunSetArgumentDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const RunSetArgumentDataTable = DataTableHoc(BaseRunSetArgumentDataTable);

/**
 * RunSetArguments main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RunSetArguments component.
 */
const RunSetArguments = ({ runSet }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  /**
   * show edit content slider
   * @param {*} dataItem
   */
  const editContentSlider = (dataItem) => {
    setEditItem(dataItem);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          <RunSetArgumentDataTable
            runSet={runSet}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RUNSETARGUMENTS}
            dataColumns={dataColumnsArray}
            gridColumnsList={() => GridColumnsList({ editContentSlider })}
            dataTableName={RUNSETARGUMENTS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RUNSETARGUMENTS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RUNSETARGUMENTS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            editItem={editItem}
            isGridSortable={{ sortable: true, sendPartialData: false }}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default RunSetArguments;
